<template>
  <div class="availability-counter">
    <bg-skeleton
      v-if="isLoading"
      data-testid="availabilityCounterLoading"
      ellipsed
      :width="28"
      :height="36"
    />

    <bg-tooltip
      v-else-if="isLoaded"
      variant="dark"
      trigger="hover"
      :closable="false"
    >
      <bg-text
        size="body-4"
        data-testid="availableCounter"
        :class="availabilityCounterClasses"
      >
        {{ availableRoomCount }}
      </bg-text>

      <template #tooltip-content>
        <div>
          <bg-text size="body-2">
            {{ dateLabel }}
          </bg-text>
          <bg-text v-if="availableRoomCount > 0" size="body-2" class="mt-8">
            {{ availableRoomCount }} kamar tersedia dari
            {{ totalRoomCount }} kamar
          </bg-text>
          <bg-text v-else size="body-2" class="mt-8">Kamar penuh</bg-text>
        </div>
      </template>
    </bg-tooltip>
  </div>
</template>

<script>
import { BgText, BgTooltip, BgSkeleton } from 'bangul-vue';

import { AsyncState } from '@admin_store/modules/calendarView';
import { dateFormatterToDisplay } from '@/utils/formatter';

export default {
  name: 'CalendarViewAvailabilityCounter',
  components: {
    BgText,
    BgTooltip,
    BgSkeleton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    unitType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fetchState() {
      return this.data.availableRoom.asyncState;
    },
    isLoading() {
      return this.fetchState === AsyncState.LOADING;
    },
    isLoaded() {
      return this.fetchState === AsyncState.SUCCESS;
    },
    roomData() {
      return this.data.availableRoom.data.find(
        data => data.unit_type === this.unitType.unit_type
      );
    },
    dateLabel() {
      return dateFormatterToDisplay(this.data.date, 'D MMMM YYYY');
    },
    availableRoomCount() {
      return this.roomData?.available_room_count || 0;
    },
    totalRoomCount() {
      return this.roomData?.room_count || 0;
    },
    availabilityCounterClasses() {
      const baseClass = 'availability-counter__info';

      return [
        baseClass,
        {
          [`${baseClass}--empty`]: this.availableRoomCount <= 0,
        },
      ];
    },
  },
};
</script>

<style lang="scss" src="./CalendarViewAvailabilityCounter.scss" scoped></style>
