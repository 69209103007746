<template>
  <bg-modal
    v-model="isShowModal"
    title="Data terkirim. Lakukan konfirmasi data booking di Bangkerupux."
    description="Anda telah mengirim data booking ke Bangkerupux. Agar data penyewa tersimpan, lakukan konfirmasi dengan klik “Accept” di Bangkerupux."
    button-main-text="ke Bangkerupux"
    desktop-size="sm"
    @click-main-action="goToBangkerupuxBookingManagement"
  />
</template>

<script>
import { BgModal } from 'bangul-vue';
import { useModalVisibility } from '@/composables/useModalVisibility';

export default {
  name: 'NewBookingSubmitRedirectionModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },
  components: {
    BgModal,
  },
  methods: {
    goToBangkerupuxBookingManagement() {
      this.isShowModal = false;

      window.open(
        `${process.env.VUE_APP_MAMIKOS_ADMIN_BASE_URL}/admin/booking/users`,
        '_blank',
        'noopener noreferrer'
      );
    },
  },
};
</script>
