<template>
  <div :class="rootClasses" data-testid="calendarViewHeadTile">
    <div class="cv-daily__head-label">
      <bg-text size="title-5" tag="span">
        {{ dateNumber }}
      </bg-text>
      <bg-text size="body-3" tag="span">
        {{ day }}
      </bg-text>
    </div>
  </div>
</template>

<script>
import { BgText } from 'bangul-vue';

export default {
  name: 'CalendarViewHeadTile',

  components: {
    BgText,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isWeekend: {
      type: Boolean,
      default: false,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    day: {
      type: String,
      required: true,
    },
    dateNumber: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    rootClasses() {
      return [
        'cv-daily__head-day cv__tile',
        {
          'cv-daily__head-day--weekend': this.isWeekend,
          'cv-daily__head-day--today': this.isToday,
          'cv-daily__head-day--active': this.isActive,
        }
      ]
    },
  },
};
</script>

<style lang="scss" src="./CalendarViewHeadTile.scss" scoped></style>
