<template>
  <div class="room-allotment-calendar-view">
    <occupied-room-modal
      :is-open.sync="isOccupiedRoomModalOpen"
      :contract.sync="selectedContract"
      @click-relocation-button="setRoomRelocationModal"
      @click-see-relocated-room="handleSeeRelocatedRoomClicked"
    />

    <out-of-order-modal
      :is-modal-open.sync="isOutOfOrderModalOpen"
      :has-access-out-of-order="hasAccessOutOfOrder"
      :contract="selectedOutOfOrderData"
      @success-updated="handleSuccessMarkOutOfOrder"
      @success-submitted="handleSuccessMarkOutOfOrder"
      @success-deactivated="handleSuccessMarkOutOfOrder(null)"
    />

    <room-relocation-modal
      v-model="isShowRoomRelocationModal"
      :contract="roomRelocationContract || {}"
      @success-submitted="handleSuccessRelocatedRoom"
      @close="setRoomRelocationModal(null)"
    />

    <div class="room-allotment-calendar-view__header">
      <header-calendar-view
        :visible-month-label="visibleMonthLabel"
        @on-click-today="swipeDate(timeline.PRESENT)"
        @filter-clicked="isFilterModalOpen = true"
      />

      <calendar-view-head
        :days="days"
        :disable-next-navigation="hasReachedFutureEdge"
        :disable-previous-navigation="hasReachedPastEdge"
        :scroll-left="calendarContentScrollLeft"
        @head-scrolled="calendarContentScrollLeft = $event"
        @on-swipe-date="swipeDate"
        @date-focused="handleDateFocused"
      />
    </div>

    <calendar-view
      ref="calendarView"
      :has-access-out-of-order="hasAccessOutOfOrder"
      :scroll-left="calendarContentScrollLeft"
      @content-scrolled="calendarContentScrollLeft = $event"
      @days-changed="days = $event"
      @on-click-room="openOutOfOrderModal($event, true)"
      @on-swipe-date="handleSwipeDate"
      @action-click-tenant-label="handleClickTenantLabel"
      @action-reached-edge="setReachedEdgeState"
      @date-focused="handleDateFocused"
    />

    <infinite-scroll
      ref="infiniteScroll"
      :loading="infiniteScroll.loading"
      @on-fire="loadMore"
    />

    <room-allotment-filter-modal
      data-testid="headerCalendarView-Filter"
      v-model="isFilterModalOpen"
      :active-filters="activeFilters"
      @on-reset-filter="handleResetFilter"
      @on-apply-filter="handleApplyFilter"
    />

    <new-booking-submit-redirection-modal
      v-model="isShowNewBookingSubmitRedirectionModal"
    />
  </div>
</template>

<script>
import HeaderCalendarView from '@admin_organisms/HeaderCalendarView';
import EventBus from 'Utils/event-bus';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import roomAllotmentApi from '@admin_endpoints/room-allotment';
import dayjs from 'dayjs';
import { AsyncState } from '@admin/store/modules/calendarView';
import Store from '@admin/store';
import { dateFormatterToSend } from 'Utils/formatter';

import CalendarView from './components/CalendarView';
import CalendarViewHead from './components/CalendarViewHead';
import InfiniteScroll from './components/InfiniteScroll';
import NewBookingSubmitRedirectionModal from './components/NewBookingSubmitRedirectionModal';
import RoomRelocationModal from './components/RoomRelocationModal';

import { timeline } from './components/CalendarView/utils';
import checkDateRange from './components/CalendarView/utils/checkDateRange';

export default {
  name: 'RoomAllotmentCalendarView',

  components: {
    HeaderCalendarView,
    CalendarView,
    CalendarViewHead,
    InfiniteScroll,
    NewBookingSubmitRedirectionModal,
    RoomRelocationModal,
    OutOfOrderModal: () =>
      import(
        /* webpackChunkName: "out-of-order-modal" */
        './components/OutOfOrderModal'
      ),
    OccupiedRoomModal: () =>
      import(
        /* webpackChunkName: "occupied-room-modal" */
        './components/OccupiedRoomModal'
      ),
    RoomAllotmentFilterModal: () =>
      import(
        /* webpackChunkName: "room-allotment-calendar-view-filter-modal" */
        '@admin_molecules/RoomAllotmentFilterModal'
      ),
  },

  data() {
    return {
      timeline,
      calendarContentScrollLeft: 0,
      hasReachedPastEdge: false,
      hasReachedFutureEdge: false,
      days: [],
      activeFilters: {},
      queryParams: {},
      calendarViewDates: {},
      pagination: {
        limit: 15,
        offset: 0,
      },
      isOutOfOrderModalOpen: false,
      isOccupiedRoomModalOpen: false,
      isShowNewBookingSubmitRedirectionModal: false,
      selectedOutOfOrderData: {},
      selectedContract: {},
      isFilterModalOpen: false,
      isShowRoomRelocationModal: false,
      roomRelocationContract: null,
      toast: {
        isOpen: false,
        message: '',
      },
    };
  },

  computed: {
    ...mapState('calendarView', ['infiniteScroll', 'isFromSubmitNewBooking']),
    ...mapGetters('calendarView', ['getVisibleDateRange']),
    ...mapGetters(['xSelectedPropertyId']),

    hasAccessOutOfOrder() {
      return this.$store.getters.xCheckUserPermission('access-out-of-order');
    },
    visibleMonthLabel() {
      const { startDate, endDate } = this.getVisibleDateRange;

      if (!startDate || !endDate) return '';

      const startDateDayJs = dayjs(startDate);
      const endDateDayJs = dayjs(endDate);

      if (!startDateDayJs.isSame(endDateDayJs, 'year')) {
        return `${startDateDayJs.format('MMMM YYYY')} - ${endDateDayJs.format(
          'MMMM YYYY'
        )}`;
      }

      if (endDateDayJs.isAfter(startDateDayJs, 'month')) {
        return `${startDateDayJs.format('MMMM')} - ${endDateDayJs.format(
          'MMMM YYYY'
        )}`;
      }

      return startDateDayJs.format('MMMM YYYY');
    },
    xPropertyList() {
      return this.$store.state.roomAllotment.xPropertyList;
    },
    xPropertyListIsEmpty() {
      return this.$store.getters.xPropertyListIsEmpty;
    },
    propertyId() {
      return this.$route.query.property_id || this.xSelectedPropertyId;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(value) {
        this.activeFilters = { ...value.query };
        this.handleRouteChange(value.query);
      },
    },
  },

  beforeRouteEnter(_, __, next) {
    Store.commit('calendarView/setFilterDate', false);
    next();
  },

  beforeRouteLeave(_, __, next) {
    this.resetState();
    next();
  },

  created() {
    this.checkPageRedirection();
  },

  methods: {
    ...mapMutations(['xSetPropertyList', 'xSetSelectedProperty']),
    ...mapMutations('calendarView', [
      'setAsyncState',
      'resetState',
      'resetAvailableRoomCounter',
      'setFromSubmitNewBookingState',
      'updateActiveDay',
    ]),
    ...mapActions('calendarView', [
      'fetchCalendarViewData',
      'fetchTotalAvailableRoom',
    ]),

    swipeDate(selectedTimeline) {
      EventBus.$emit('cv-swipe-date', selectedTimeline);
    },

    setReachedEdgeState(edge) {
      this.hasReachedPastEdge = edge === timeline.PAST;
      this.hasReachedFutureEdge = edge === timeline.FUTURE;
    },

    handleDateFocused(selectedDate) {
      this.updateActiveDay(selectedDate);

      // Get available room by selected day
      if (typeof this.xSelectedPropertyId !== 'undefined') {
        this.fetchTotalAvailableRoom({
          date: selectedDate,
          property_id: this.xSelectedPropertyId,
        });
      }
    },

    handleSwipeDate({ startDate, endDate }) {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            start_date: startDate,
            end_date: endDate,
          },
        })
        .catch(() => {});
    },

    async handleRouteChange(query) {
      if (this.xPropertyListIsEmpty) {
        this.setAsyncState(AsyncState.LOADING);
        const property = await this.fetchPropertyLists();
        const properties = Object.entries(property).map(([label, val]) => ({
          label,
          val,
        }));

        this.xSetPropertyList(properties);
      }

      let selectedProperty = {};

      if (query.property_id) {
        const findProperty = this.xPropertyList.find(
          prop => prop.val === +query.property_id
        );

        selectedProperty = findProperty || this.xPropertyList[0];
      } else {
        selectedProperty = this.xPropertyList[0];
      }

      const isStillInRoomAllotmentCalendarViewPage =
        this.$route.name === 'room-allotment-calendar-view' &&
        this.$route.meta?.breadcrumbs?.[1];

      if (!isStillInRoomAllotmentCalendarViewPage) {
        return;
      }

      this.$route.meta.breadcrumbs[1].name = selectedProperty.label;

      this.xSetSelectedProperty(selectedProperty);

      await this.waitingForUpdatedVuex();

      const params = {
        property_id: selectedProperty.val,
        ...this.pagination,
        ...this.getQueryDates(query, this.getVisibleDateRange),
        ...this.getQueryFilter(query),
        ...this.getQuerySearch(query),
      };

      this.pagination = params;

      await this.fetchCalendarViewData({ params });

      if (this.infiniteScroll.has_more) {
        this.$refs.infiniteScroll?.setup();
      }
    },

    async fetchPropertyLists() {
      try {
        const response = await roomAllotmentApi.getPropertyList();
        if (response.data.status) {
          return response.data.data;
        }
      } catch (error) {
        console.error(error);
      }

      return {};
    },

    getQueryDates(query, { startDate, endDate }) {
      if (checkDateRange(query.start_date, query.end_date)) {
        return {
          start_date: query.start_date,
          end_date: query.end_date,
        };
      }
      return {
        start_date: startDate,
        end_date: endDate,
      };
    },

    waitingForUpdatedVuex() {
      return new Promise(resolve => setImmediate(resolve));
    },

    async loadMore() {
      this.pagination.offset += this.pagination.limit;

      try {
        await this.fetchCalendarViewData({
          params: this.pagination,
          isInfiniteScroll: true,
        });
      } catch (error) {
        this.pagination.offset -= this.pagination.limit;
      }

      if (!this.infiniteScroll.has_more) {
        this.$refs.infiniteScroll?.unobserve();
      }
    },

    openOutOfOrderModal(data, isNew = false) {
      let selectedOutOfOrderData = { ...data };

      if (isNew) {
        selectedOutOfOrderData = {
          ...selectedOutOfOrderData,
          out_of_order_data: null,
          is_out_of_order: false,
        };
      }

      this.selectedOutOfOrderData = selectedOutOfOrderData;

      this.isOutOfOrderModalOpen = true;
    },

    resetToastMessage(isOpen) {
      if (!isOpen) this.toast.message = '';
    },

    getQueryFilter({ room_status, room_status_ooo, booking_status }) {
      const toArray = value => {
        if (!value) {
          return undefined;
        }

        return Array.isArray(value) ? value : [value];
      };

      return {
        room_status: toArray(room_status),
        room_status_ooo: toArray(room_status_ooo),
        booking_status: toArray(booking_status),
      };
    },

    getQuerySearch({ search_value, search_by }) {
      return {
        search_value,
        search_by,
      };
    },

    handleClickTenantLabel(contract) {
      if (contract.isOutOfOrder) {
        const { room, ...contractData } = contract;
        this.openOutOfOrderModal({
          ...room,
          is_out_of_order: true,
          out_of_order_data: { ...contractData },
        });
      } else {
        this.openOccupiedRoomModal(contract);
      }
    },

    openOccupiedRoomModal(contract) {
      this.selectedContract = { ...contract };
      this.isOccupiedRoomModalOpen = true;
    },

    async handleSuccessMarkOutOfOrder(startDate) {
      if (startDate) {
        const startOfStartDateMonth = dateFormatterToSend(
          dayjs(startDate).startOf('month')
        );

        const isStartInCurrentVisibleDateRange =
          this.getVisibleDateRange.startDate === startOfStartDateMonth;

        if (!isStartInCurrentVisibleDateRange) {
          this.$router.replace({
            query: {
              property_id: this.propertyId,
              start_date: startOfStartDateMonth,
            },
          });

          return;
        }
      }

      this.$refs.infiniteScroll?.unobserve();
      this.resetAvailableRoomCounter();
      this.pagination.offset = 0;

      await this.fetchCalendarViewData({
        params: this.pagination,
      });

      if (this.infiniteScroll.has_more) {
        this.$refs.infiniteScroll?.setup();
      }
    },

    checkPageRedirection() {
      if (this.isFromSubmitNewBooking) {
        this.isShowNewBookingSubmitRedirectionModal = true;
        this.setFromSubmitNewBookingState(false);
      }
    },

    setRoomRelocationModal(contract = null) {
      if (contract) {
        this.roomRelocationContract = { ...contract };
        this.isShowRoomRelocationModal = true;
      } else {
        this.isShowRoomRelocationModal = false;
        this.roomRelocationContract = null;
      }
    },

    handleSuccessRelocatedRoom({ roomName, selectedRoomName }) {
      const startOfCurrentDateMonth = dateFormatterToSend(
        dayjs().startOf('month')
      );

      const currentDateMonth = this.$route.query.start_date
        ? undefined
        : startOfCurrentDateMonth;

      this.$router.replace({
        query: {
          ...this.$route.query,
          start_date: currentDateMonth,
          end_date: undefined,
        },
      });

      this.$toast.show(
        `Nomor kamar berubah dari ${roomName} ke ${selectedRoomName}`
      );
    },

    handleSeeRelocatedRoomClicked({ date, phone }) {
      this.$router.push({
        query: {
          property_id: this.propertyId,
          start_date: dateFormatterToSend(date),
          search_by: 'phone',
          search_value: phone,
        },
      });
    },

    handleResetFilter() {
      const { property_id, name: routeName } = this.$route.query;
      this.$router
        .replace({
          name: routeName,
          query: {
            property_id,
          },
        })
        .catch(() => {});
    },

    handleApplyFilter(filters) {
      const { property_id } = this.$route.query;

      const { start_date: startDate, end_date: endDate, filter } = filters;

      this.$router
        .replace({
          query: {
            property_id,
            start_date: startDate,
            end_date: endDate,
            ...filter,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" src="./RoomAllotmentCalendarView.scss" scoped></style>
