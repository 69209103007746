<template>
  <div class="organism-header-calendar-view">
    <div class="organism-header-calendar-view__row">
      <div class="organism-header-calendar-view__col">
        <div class="organism-header-calendar-view__property-select">
          <bg-skeleton v-if="!xSelectedPropertyId" width="100%" height="40px" />
          <bg-select
            v-else
            data-testid="headerCalendarView-SearchProperty"
            searchable
            size="md"
            :value="xSelectedPropertyId"
            :options="xPropertyList"
            @input="handleSelectProperty"
          />
        </div>
      </div>

      <div class="organism-header-calendar-view__col">
        <bg-search-bar
          v-model="searchValue"
          type="text"
          class="organism-header-calendar-view__search"
          size="md"
          with-select-input
          :show-clear-text-icon="false"
          :placeholder="placeholderSearchBy"
          :select-options="searchOptions"
          :select-value="searchBy"
          @select-change="handleChangeSearchBy"
          @keyup.enter="handleSearch"
        />
        <bg-button-icon
          class="bg-u-ml-xs"
          icon="filter"
          @click="$emit('filter-clicked')"
        />
      </div>
    </div>

    <div class="organism-header-calendar-view__row">
      <div class="organism-header-calendar-view__col">
        <div class="organism-header-calendar-view__wrap-today">
          <bg-text
            size="title-2"
            class="organism-header-calendar-view__text-today"
          >
            {{ visibleMonthLabel }}
          </bg-text>
          <bg-button size="sm" @click="$emit('on-click-today')">
            Hari ini
          </bg-button>
        </div>
      </div>
      <div class="organism-header-calendar-view__col">
        <bg-button-icon icon="activity" @click="goToChangeLog">
          Riwayat perubahan data kamar
        </bg-button-icon>

        <bg-button-icon
          class="bg-u-ml-sm"
          icon="tenant-bill"
          @click="goToTableView"
        >
          Tampilan Tabel
        </bg-button-icon>

        <add-tenant-menu-button
          class="bg-u-ml-sm"
          :property-id="xSelectedPropertyId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BgSelect,
  BgButtonIcon,
  BgButton,
  BgSearchBar,
  BgText,
  BgSkeleton,
} from 'bangul-vue';
import { mapMutations } from 'vuex';
import AddTenantMenuButton from '../RoomAllotment/AddTenantMenuButton';

export default {
  name: 'HeaderCalendarView',

  components: {
    BgSelect,
    BgButtonIcon,
    BgButton,
    BgSearchBar,
    BgText,
    BgSkeleton,
    AddTenantMenuButton,
  },

  props: {
    visibleMonthLabel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      PropertyListValue: '',
      isFilterModalOpen: false,
      searchValue: '',
      searchBy: 'booking_code',
      searchOptions: [
        {
          val: 'booking_code',
          label: 'Booking Code',
        },
        {
          val: 'tenant_name',
          label: 'Nama',
        },
        {
          val: 'phone',
          label: 'Nomor Handphone',
        },
        {
          val: 'room_number',
          label: 'Nomor Kamar',
        },
        {
          val: 'unit_type',
          label: 'Tipe Kamar',
        },
      ],
    };
  },

  computed: {
    xPropertyList() {
      return this.$store.state.roomAllotment.xPropertyList;
    },
    xSelectedPropertyId() {
      return this.$store.getters.xSelectedPropertyId;
    },
    placeholderSearchBy() {
      const { label } =
        this.searchOptions.find(({ val }) => val === this.searchBy) || {};

      return `Masukkan ${label}`;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler({ query }) {
        this.searchBy = query.search_by ?? this.searchBy;
        this.searchValue = query.search_value || '';
      },
    },
  },

  methods: {
    ...mapMutations('calendarView', ['resetAvailableRoomCache']),

    handleSearch() {
      const query = {
        search_by: this.searchValue === '' ? undefined : this.searchBy,
        search_value: this.searchValue || undefined,
      };

      this.changeQuery(query);
    },

    goToTableView() {
      this.$router.push({
        name: 'room-allotment-table-view',
        query: {
          property_id: this.xSelectedPropertyId,
        },
      });
    },

    goToChangeLog() {
      this.$router.push({
        name: `room-allotment-change-log`,
        params: {
          propertyId: this.xSelectedPropertyId,
        },
      });
    },

    handleChangeSearchBy(val) {
      this.searchBy = val;
      this.searchValue = '';
    },

    handleSelectProperty(selectedId) {
      if (this.xSelectedPropertyId !== selectedId) {
        this.resetAvailableRoomCache();
        this.changeQuery({
          property_id: selectedId,
        });
      }
    },

    changeQuery(updatedQuery) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            ...updatedQuery,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" src="./HeaderCalendarView.scss" scoped></style>
