<template>
  <div>
    <bg-modal
      class="relocation-modal"
      data-testid="relocationModal"
      v-model="isShowModal"
    >
      <div class="relocation-modal__title">
        <bg-text size="heading-4">Pindah Kamar</bg-text>
        <bg-text size="body-landing">{{ roomType }}</bg-text>
      </div>
      <bg-divider class="mt-16 mb-16" />

      <bg-list-item>
        <template #default>Nama Penyewa</template>
        <template #right-content>{{ tenantName }}</template>
      </bg-list-item>
      <bg-list-item class="mt-16">
        <template #default>Nomor Kamar (Saat ini)</template>
        <template #right-content>{{ roomNameLabel }}</template>
      </bg-list-item>

      <bg-divider class="mt-16 mb-16" />

      <bg-list-item>
        <template #default><bg-text>Pilih kamar baru</bg-text></template>
        <template #right-content>
          <bg-skeleton width="178px" height="24px" v-if="isFetchingRooms" />
          <bg-text size="body-2" v-else>
            <i>Masih ada {{ totalAvailableRooms }} kamar kosong</i>
          </bg-text>
        </template>
      </bg-list-item>

      <bg-search-bar
        placeholder="Masukkan nomor kamar"
        v-model="roomSearchKeyword"
        class="mt-16 mb-16"
      />

      <div class="relocation-modal__skeleton" v-if="isFetchingRooms">
        <bg-skeleton
          v-for="i in 3"
          :key="i"
          height="52px"
          width="100%"
          class="mb-12"
        />
      </div>

      <div class="relocation-modal__rooms" v-else>
        <bg-radio
          v-for="(room, index) in filteredRooms"
          :key="index"
          :id="`room-${index}`"
          class="mb-12"
          :label-title="`Kamar ${room.name}`"
          :label="`Lantai ${room.floor}`"
          :custom-value="room.id"
          v-model="selectedRoomId"
          :disabled="!room.is_available"
        />
      </div>

      <template #footer>
        <div class="relocation-modal__action">
          <bg-button
            variant="primary"
            size="lg"
            :disabled="!selectedRoomId"
            @click="isShowConfirmModal = true"
          >
            Simpan
          </bg-button>
        </div>
      </template>
    </bg-modal>

    <!-- Confirmation Modal -->
    <bg-modal
      v-model="isShowConfirmModal"
      title="Anda akan memindahkan penyewa ke kamar lain"
      button-main-text="Setuju"
      button-second-text="Kembali"
      desktop-size="sm"
      @click-second-action="isShowConfirmModal = false"
      @click-main-action="submit"
    >
      <bg-text
        size="body-2"
        class="relocation-modal__confirmation-description"
        data-testid="confirmModalDescription"
      >
        Penyewa <b>{{ tenantName }}</b> pindah dari kamar
        <b> {{ roomName }}</b> ke <b>{{ selectedRoomName }}.</b>
        Pastikan penyewa telah mengetahui pemindahan ini.
      </bg-text>
    </bg-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  BgModal,
  BgText,
  BgButton,
  BgRadio,
  BgSearchBar,
  BgListItem,
  BgSkeleton,
  BgDivider,
} from 'bangul-vue';
import bookingAPI from '@admin/api/endpoints/booking';
import { useModalVisibility } from '@/composables/useModalVisibility';

const DEFAULT_ERROR_MESSAGE = 'Terjadi kesalahan silahkan coba lagi.';

export default {
  name: 'RoomRelocationModal',
  components: {
    BgModal,
    BgText,
    BgButton,
    BgRadio,
    BgSearchBar,
    BgListItem,
    BgSkeleton,
    BgDivider,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },
  data() {
    return {
      roomSearchKeyword: '',
      isFetchingRooms: true,
      isSubmitRelocation: false,
      rooms: [],
      selectedRoomId: null,
      isShowConfirmModal: false,
    };
  },
  computed: {
    contractId() {
      return this.contract.contract_id;
    },
    tenantName() {
      return this.contract.tenant_name;
    },
    roomContract() {
      return this.contract?.room || {};
    },
    roomId() {
      return this.roomContract.unit_id;
    },
    roomName() {
      return this.roomContract.room_name;
    },
    roomNameLabel() {
      return this.roomName ? `Kamar ${this.roomName}` : '';
    },
    roomType() {
      const roomType = this.roomContract.unit_type;
      return roomType ? `(${roomType})` : '';
    },
    checkinDate() {
      return this.contract.start_date;
    },
    checkoutDate() {
      return this.contract.end_date;
    },
    totalAvailableRooms() {
      return this.rooms.filter(room => room.is_available).length;
    },
    filteredRooms() {
      return this.rooms.filter(room =>
        room.name.includes(this.roomSearchKeyword)
      );
    },
    selectedRoomName() {
      const { name: selectedRoomName } =
        this.filteredRooms.find(room => room.id === this.selectedRoomId) || {};

      return selectedRoomName;
    },
  },
  watch: {
    isShowModal(value) {
      if (value) {
        this.fetchPropertyRooms();
      } else {
        this.roomSearchKeyword = '';
        this.selectedRoomId = null;
      }
    },
  },
  methods: {
    handleApiRequestError(error) {
      const errorMessage =
        error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;

      this.$toast.show(errorMessage);
      this.$error.report(error);
    },
    async fetchPropertyRooms() {
      this.isFetchingRooms = true;

      const today = dayjs();
      const hasPastCheckinDate = dayjs(this.checkinDate).isBefore(today);

      const relocationStartDate = hasPastCheckinDate
        ? today.format('YYYY-MM-DD')
        : this.checkinDate;

      try {
        const res = await bookingAPI.getListingAvailableRooms(this.roomId, {
          checkIn: relocationStartDate,
          checkOut: this.checkoutDate,
          status: 'all',
        });

        this.rooms = res?.data?.room_units || [];
      } catch (error) {
        this.handleApiRequestError(error);
      } finally {
        this.isFetchingRooms = false;
      }
    },
    async submit() {
      if (!this.isSubmitRelocation && this.selectedRoomId) {
        this.isSubmitRelocation = true;

        try {
          await bookingAPI.postRoomRelocation({
            contract_id: this.contractId,
            new_designer_room_id: this.selectedRoomId,
          });

          const { roomName, selectedRoomName } = this;

          this.$emit('success-submitted', {
            roomName,
            selectedRoomName,
          });
        } catch (error) {
          this.handleApiRequestError(error);
        } finally {
          this.isSubmitRelocation = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./RoomRelocationModal.scss" scoped></style>
