<template>
  <div
    :class="['tenant-label', rootClasses]"
    :style="`width: ${width}px`"
    data-testid="tenantLabel"
  >
    <bg-text v-if="isAutoextend" size="label-1" class="ml-0">
      {{ autoextendLabel }}
    </bg-text>

    <bg-text v-else-if="isOutOfOrder" size="label-1" class="ml-0">
      {{ outOfOrderLabel }}
    </bg-text>

    <template v-else>
      <bg-label-rainbow :color="getBookingStatus(status).color">
        {{ getBookingStatus(status).text }}
      </bg-label-rainbow>

      <bg-text v-if="this.hasLabelName" size="title-4">
        {{ tenantName }}
      </bg-text>
    </template>
  </div>
</template>

<script>
import { getBookingStatus } from 'Utils/bookingStatus';

import { BgText, BgLabelRainbow } from 'bangul-vue';
import { getOutOfOrderCategoryLabel } from '@admin/pages/RoomAllotmentCalendarView/utils/outOfOrder';

export default {
  name: 'TenantLabel',

  components: {
    BgText,
    BgLabelRainbow,
  },

  props: {
    width: {
      type: Number,
      default: 0,
    },
    isOutOfOrder: {
      type: Boolean,
      default: false,
    },
    isAutoextend: {
      type: Boolean,
      default: false,
    },
    hasAutoextend: {
      type: Boolean,
      default: false,
    },
    outOfOrderCategory: {
      type: String,
      default: '',
    },
    outOfOrderNote: {
      type: String,
      default: '',
    },
    hasStartInPast: {
      type: Boolean,
      default: false,
    },
    hasEndInFuture: {
      type: Boolean,
      default: false,
    },
    onlyATile: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    tenantName: {
      type: String,
      default: '',
    },
  },

  computed: {
    isLongStay() {
      return !this.isAutoextend && !this.isOutOfOrder;
    },
    rootClasses() {
      return {
        'tenant-label--invisible-start': this.hasStartInPast,
        'tenant-label--invisible-end': this.hasEndInFuture,
        'tenant-label--only-a-tile': this.onlyATile,
        'tenant-label--long-stay': this.isLongStay,
        'tenant-label--out-of-order': this.isOutOfOrder,
        'tenant-label--autoextend': this.isAutoextend,
        'tenant-label--has-autoextend': this.hasAutoextend,
      };
    },
    hasLabelName() {
      return !this.isOutOfOrder && !this.onlyATile;
    },
    outOfOrderLabel() {
      if (!this.isOutOfOrder) return '';

      if (this.outOfOrderCategory === 'other') {
        return `OOO: ${this.outOfOrderNote || '-'}`;
      }

      return (
        getOutOfOrderCategoryLabel(this.outOfOrderCategory) ||
        getBookingStatus(this.status)?.text
      );
    },
    autoextendLabel() {
      if (!this.isAutoextend) return '';

      return `Autoextend - ${this.tenantName}`;
    },
  },

  methods: {
    getBookingStatus,
  },
};
</script>

<style lang="scss" src="./TenantLabel.scss" scoped></style>
