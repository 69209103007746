<template>
  <div>
    <bg-dropdown
      menu-placement="bottom-end"
      menu-size="fit-to-trigger"
      role="menu"
    >
      <template #trigger>
        <bg-button-icon icon="add-plus" variant="primary">
          Tambah Penyewa
        </bg-button-icon>
      </template>

      <bg-dropdown-item
        v-for="(option, index) in bookingTypeOptions"
        :key="index"
        role="menuitem"
        @click="handleMenuClicked(option)"
      >
        {{ option.label }}
      </bg-dropdown-item>
    </bg-dropdown>

    <booking-type-modal
      v-model="isShowBookingTypeModal"
      @selected="goToAddTenantPath"
    />
  </div>
</template>

<script>
import { BgDropdown, BgDropdownItem, BgButtonIcon } from 'bangul-vue';
import BookingTypeModal from '../BookingTypeModal';

export default {
  name: 'RoomAllotmentAddTenantButton',
  components: {
    BgDropdown,
    BgDropdownItem,
    BgButtonIcon,
    BookingTypeModal,
  },
  props: {
    propertyId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      isShowBookingTypeModal: false,
      bookingTypeOptions: [
        {
          path: 'dbet',
          label: 'DBET',
        },
        {
          menu: 'booking',
          label: 'Booking',
        },
      ],
    };
  },
  methods: {
    handleMenuClicked({ menu = '', path = '' } = {}) {
      if (path) {
        this.goToAddTenantPath(path);
      } else if (menu === 'booking') {
        this.isShowBookingTypeModal = true;
      }
    },
    goToAddTenantPath(path) {
      if (!this.propertyId) return;

      this.$router.push({
        name: `room-allotment-add-tenant-${path}`,
        params: {
          propertyId: this.propertyId,
        },
      });
    },
  },
};
</script>
