<template>
  <bg-modal
    class="booking-type-modal"
    v-model="isShowModal"
    title="Tipe Booking"
  >
    <bg-select
      class="booking-type-modal__select"
      :options="bookingTypeOptions"
      v-model="selectedBookingType"
      placeholder="Pilih tipe booking"
      data-testid="bookingType_ddl"
    />

    <template #footer>
      <bg-button
        @click="handleNextClicked"
        :disabled="!selectedBookingType"
        class="booking-type-modal__action"
        variant="secondary"
      >
        Selanjutnya
      </bg-button>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgSelect, BgButton } from 'bangul-vue';
import { useModalVisibility } from '@/composables/useModalVisibility';

export default {
  name: 'BookingTypeModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BgModal,
    BgSelect,
    BgButton,
  },
  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },
  data() {
    return {
      selectedBookingType: '',
      bookingTypeOptions: [
        { val: 'new-booking', label: 'New Booking' },
        { val: 'reschedule', label: 'Reschedule' },
        { val: 'relocation', label: 'Relocation' },
      ],
    };
  },
  watch: {
    isShowModal() {
      this.selectedBookingType = '';
    },
  },
  methods: {
    handleNextClicked() {
      this.$emit('selected', this.selectedBookingType);
      this.isShowModal = false;
    },
  },
};
</script>

<style lang="scss" src="./BookingTypeModal.scss" scoped></style>
