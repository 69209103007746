import _find from 'lodash/find';

export const OUT_OF_ORDER_CATEGORIES = [
  {
    label: 'Room preparation',
    val: 'room_preparation',
  },
  {
    label: 'DBET',
    val: 'dbet',
  },
  {
    label: 'Renovasi',
    val: 'renovation',
  },
  {
    label: 'Short Stay',
    val: 'short_stay',
  },
  {
    label: 'Undertable',
    val: 'undertable',
  },
  {
    label: 'Dipakai Owner',
    val: 'used_by_owner',
  },
  {
    label: 'Internal Case',
    val: 'internal_case',
  },
  {
    label: 'Terminate',
    val: 'terminate',
  },
  {
    label: 'Lainnya',
    val: 'other',
  },
];

export const getOutOfOrderCategoryLabel = val =>
  _find(OUT_OF_ORDER_CATEGORIES, { val })?.label || '';
