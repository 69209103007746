<template>
  <div :class="rootClasses" data-testid="calendarViewTile">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CalendarViewTile',

  props: {
    isWeekend: {
      type: Boolean,
      default: false,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    /** Set true if there's tenant's label */
    isOccupied: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      return [
        'cv-daily__day cv__tile',
        {
          'cv-daily__day--weekend': this.isWeekend,
          'cv-daily__day--today': this.isToday,
          'cv-daily__day--occupied': this.isOccupied,
        }
      ]
    },
  },
};
</script>
