<template>
  <div class="cv-head">
    <div class="cv-head__nav-left">
      <bg-button-icon
        icon="chevron-left"
        class="bg-u-mr-sm"
        :disabled="disablePreviousNavigation"
        @click="swipeDate(timeline.PAST)"
      />
    </div>

    <div class="cv-head__tiles-wrapper">
      <div
        ref="tiles"
        class="cv-head__tiles"
        data-testid="cvHeadTiles"
        @scroll="$emit('head-scrolled', $event.target.scrollLeft)"
      >
        <calendar-view-head-tile
          v-for="day in days"
          class="cv-head__tile"
          :is-active="day.isActive"
          :is-weekend="day.isWeekend"
          :is-today="day.isToday"
          :day="day.day"
          :date-number="day.dateNumber"
          :key="day.date"
          @click.native="$emit('date-focused', day.date)"
        />
      </div>
    </div>

    <div class="cv-head__nav-right">
      <bg-button-icon
        icon="chevron-right"
        class="bg-u-ml-sm"
        :disabled="disableNextNavigation"
        @click="swipeDate(timeline.FUTURE)"
      />
    </div>
  </div>
</template>

<script>
import { BgButtonIcon } from 'bangul-vue';

import CalendarViewHeadTile from '../CalendarView/partials/CalendarViewHeadTile';

import { timeline } from '../CalendarView/utils';

export default {
  name: 'CalendarViewHeader',
  components: {
    BgButtonIcon,
    CalendarViewHeadTile,
  },
  props: {
    days: {
      type: Array,
      required: true,
    },
    disableNextNavigation: {
      type: Boolean,
      default: false,
    },
    disablePreviousNavigation: {
      type: Boolean,
      default: false,
    },
    scrollLeft: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeline,
    };
  },
  watch: {
    scrollLeft(value) {
      if (this.$refs.tiles) {
        this.$refs.tiles.scrollLeft = value;
      }
    },
  },
  methods: {
    swipeDate(selectedTimeline) {
      this.$emit('on-swipe-date', selectedTimeline);
    },
  },
};
</script>

<style lang="scss" src="./CalendarViewHead.scss" scoped></style>
