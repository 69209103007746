import { ref, watch } from '@vue/composition-api';

export const useModalVisibility = (props, emit) => {
  const isShowModal = ref(props.value);

  watch(isShowModal, value => {
    emit('input', value);
  });

  watch(
    () => props.value,
    value => {
      isShowModal.value = value;
    }
  );

  return {
    isShowModal,
  };
};
