<template>
  <div class="cv-daily">
    <div
      class="cv-daily__scrollable"
      ref="wrapper"
      @scroll="$emit('content-scrolled', $event.target.scrollLeft)"
    >
      <div class="cv-daily__wrapper">
        <div
          ref="line"
          class="cv-daily__active-line"
          :title="selectedDay.title"
        ></div>

        <!-- To keep the structure on loading state -->
        <div class="cv-daily__head">
          <div v-for="day in days" :key="day.date" />
        </div>

        <template v-if="asyncState === AsyncState.LOADING">
          <div v-for="i in 5" class="cv-daily__loading" :key="`loading-${i}`">
            <bg-skeleton width="100%" />
          </div>
        </template>

        <div
          v-else-if="asyncState === AsyncState.NO_DATA"
          class="cv-daily__no-data"
        >
          <div class="no-data__message">
            <bg-text size="title-2">Data Tidak Ditemukan</bg-text>
            <bg-text size="body-2"
              >Data tidak ditemukan di filter atau kata kunci yang Anda
              gunakan.</bg-text
            >
          </div>
        </div>

        <template
          v-else-if="
            asyncState === AsyncState.SUCCESS ||
            asyncState === AsyncState.INITIAL
          "
        >
          <div
            v-for="unitType in unitTypes"
            :key="`unit-type-${unitType.unit_type}`"
            class="cv-daily__group-by-tipe-wrapper"
          >
            <div class="cv-daily__day-wrapper cv-daily__day-counter">
              <calendar-view-tile
                v-for="day in days"
                :is-today="day.isToday"
                :is-weekend="day.isWeekend"
                :key="day.date"
              >
                <calendar-view-availability-counter
                  :data="day"
                  :unit-type="unitType"
                />
              </calendar-view-tile>
            </div>

            <div
              v-for="(room, index) in unitType.rooms"
              class="cv-daily__day-wrapper cv-daily__day-allotment"
              :key="`unit-type-${unitType.unit_type}-
                room-${room.room_name}-${index}`"
            >
              <calendar-view-tile
                v-for="day in room.days"
                :is-today="day.isToday"
                :is-weekend="day.isWeekend"
                :is-occupied="day.tenants.length > 0"
                :key="day.date + room.room_name"
              >
                <template v-if="day.tenants.length">
                  <tenant-label
                    v-for="(tenant, indexTenant) in day.tenants"
                    data-testid="contractAllotmentLabel"
                    :key="`tenant-${indexTenant}`"
                    :width="tenant.widthLabel"
                    :is-out-of-order="tenant.isOutOfOrder"
                    :is-autoextend="tenant.isAutoextend"
                    :has-autoextend="tenant.hasAutoextend"
                    :out-of-order-category="tenant.outOfOrderCategory"
                    :out-of-order-note="tenant.outOfOrderNote"
                    :has-start-in-past="tenant.hasStartInPast"
                    :has-end-in-future="tenant.hasEndInFuture"
                    :only-a-tile="tenant.onlyATile"
                    :status="tenant.booking_status"
                    :tenant-name="tenant.tenant_name"
                    @click.native="handleClickTenantLabel(tenant, room)"
                  />
                </template>
              </calendar-view-tile>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="cv-daily__download-csv-button">
      <bg-button-icon
        class="download-button"
        icon="download"
        variant="primary"
        size="lg"
      >
        Unduh CSV
      </bg-button-icon>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import { BgText, BgSkeleton, BgButtonIcon } from 'bangul-vue';
import EventBus from 'Utils/event-bus';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { AsyncState } from '@admin/store/modules/calendarView';
import CalendarViewTile from '../CalendarViewTile';
import TenantLabel from '../TenantLabel';
import CalendarViewAvailabilityCounter from '../CalendarViewAvailabilityCounter';
import { FORMAT_DATE, timeline } from '../../utils';
import 'dayjs/locale/id';

dayjs.locale('id');
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(isBetween);

const TILE_WIDTH = 36;
const HALF_TILE_WIDTH = TILE_WIDTH / 2;

export default {
  name: 'CalendarViewDaily',

  components: {
    BgText,
    BgSkeleton,
    BgButtonIcon,
    CalendarViewTile,
    TenantLabel,
    CalendarViewAvailabilityCounter,
  },

  props: {
    /**
     * Start date of visible range dates
     * format: YYYY-MMM-DD
     * if you pass with invalid format it will be replace with today date
     */
    startDate: {
      type: String,
      default: '',
    },

    /** Total month which to be limit to the future */
    futureMonthLimit: {
      type: Number,
      required: true,
      validator: value => value > 0,
    },

    passYearLimit: {
      type: Number,
      required: true,
      validator: value => value > 0,
    },

    scrollLeft: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      timeline,
      AsyncState,
      today: dayjs(),
      visibleStartDate: null,
      visibleEndDate: null,
      unitTypes: [],
    };
  },

  computed: {
    ...mapState('calendarView', [
      'asyncState',
      'calendarViewData',
      'availableRoom',
      'days',
    ]),
    ...mapGetters('calendarView', ['getTotalAvailableRoom', 'activeDay']),

    xSelectedPropertyId() {
      return this.$store.getters.xSelectedPropertyId;
    },

    pastDateLimit() {
      return this.today.set('date', 1).subtract(this.passYearLimit, 'year');
    },

    futureDateLimit() {
      return this.today
        .set('date', 1)
        .add(this.futureMonthLimit, 'month')
        .subtract(1, 'day');
    },

    selectedDay() {
      if (Object.keys(this.activeDay).length) {
        return {
          ...this.activeDay,
          title: dayjs(this.activeDay.date).format('dddd, DD MMMM YYYY'),
        };
      }

      return { title: '' };
    },
  },

  watch: {
    startDate: {
      immediate: true,
      handler(value) {
        this.setRangeMonthly(value);
      },
    },
    calendarViewData: {
      immediate: true,
      handler(value) {
        this.unitTypes = this.updateDataUnitTypes(value, this.days);
      },
    },
    days: {
      immediate: true,
      deep: true,
      handler(days) {
        this.$emit('days-changed', days);
      },
    },
    scrollLeft(value) {
      this.$refs.wrapper.scrollLeft = value;
    },
  },

  created() {
    EventBus.$on('cv-swipe-date', this.swipeDate);
  },

  mounted() {
    this.setTodayLinePosition();
  },

  updated() {
    this.setTodayLinePosition();
  },

  beforeDestroy() {
    EventBus.$off('cv-swipe-date', this.swipeDate);
  },

  methods: {
    ...mapMutations({
      xSetActiveDay: 'calendarView/setActiveDay',
      xUpdateActiveDay: 'calendarView/updateActiveDay',
    }),
    ...mapActions({
      xSetVisibleDates: 'calendarView/setVisibleDates',
      xFetchTotalAvailableRoom: 'calendarView/fetchTotalAvailableRoom',
    }),

    setTodayLinePosition() {
      if (this.$refs.line) {
        const { line } = this.$refs;
        const indexOfActiveDay = this.days.findIndex(day => day.isActive);

        if (indexOfActiveDay !== -1) {
          const selectedDay = this.days[indexOfActiveDay];
          const dayTileOrder = indexOfActiveDay + 1;
          const widthTileOrder = dayTileOrder * TILE_WIDTH;
          const centerLinePosition = widthTileOrder - HALF_TILE_WIDTH - 1;

          line.style.left = `${centerLinePosition}px`;

          if (selectedDay.isToday) {
            line.classList.add('cv-daily__active-line--today');
          } else {
            line.classList.remove('cv-daily__active-line--today');
          }

          return;
        }

        line.style.left = '-100px';
      }
    },

    setRangeMonthly(startDate) {
      let startDateDayJs = dayjs(startDate);
      let edge = '';

      if (!startDate || !startDateDayJs.isValid()) {
        startDateDayJs = this.today.set('date', 1);
      }

      const endDateDayJs = startDateDayJs.add(1, 'month').subtract(1, 'day');

      if (startDateDayJs.isSame(this.pastDateLimit, 'day')) {
        edge = timeline.PAST;
      } else if (endDateDayJs.isSame(this.futureDateLimit, 'day')) {
        edge = timeline.FUTURE;
      }

      this.visibleStartDate = startDateDayJs;
      this.visibleEndDate = endDateDayJs;

      this.xSetVisibleDates({
        startDate: startDateDayJs,
        endDate: endDateDayJs,
      });

      this.xUpdateActiveDay(this.today.format(FORMAT_DATE));
      this.$emit('action-reached-edge', edge);
    },

    swipeDate(selectedTimeline) {
      let startDate;
      let endDate;

      switch (selectedTimeline) {
        case timeline.PAST:
          startDate = this.visibleStartDate.subtract(1, 'month');
          endDate = startDate.add(1, 'month').subtract(1, 'day');
          if (startDate.isBefore(this.pastDateLimit, 'day')) {
            startDate = this.pastDateLimit;
            endDate = this.pastDateLimit.add(1, 'month');
          }
          break;

        case timeline.PRESENT:
          startDate = '';
          endDate = '';
          this.handleClickDayHead(this.today.format(FORMAT_DATE));
          break;

        case timeline.FUTURE:
          startDate = this.visibleStartDate.add(1, 'month');
          endDate = startDate.add(1, 'month').subtract(1, 'day');

          if (endDate.isAfter(this.futureDateLimit, 'day')) {
            startDate = this.futureDateLimit.subtract(1, 'month');
            endDate = this.futureDateLimit.subtract(1, 'day');
          }
          break;

        default:
          break;
      }

      if (dayjs.isDayjs(startDate) && dayjs.isDayjs(endDate)) {
        this.$emit('on-swipe-date', {
          startDate: startDate.format(FORMAT_DATE),
          endDate: endDate.format(FORMAT_DATE),
        });
      } else {
        this.$emit('on-swipe-date', {});
      }
    },

    isDateSame(date1, date2) {
      return dayjs(date1).isSame(date2, 'day');
    },

    getWidthOfOccupiedLabel({
      startDate,
      endDate,
      dayIndex,
      hasAutoextend,
      isAutoextend,
      hasStartInPast,
      hasEndInFuture,
    } = {}) {
      let tileStartDateOrder = 0;
      let tileEndDateOrder = 0;
      let autoextendReduction = 0;

      /*
        End date of tenant is before or same
        the start date contract (terminated case)
      */
      if (endDate.isSameOrBefore(startDate, 'day')) {
        return TILE_WIDTH;
      }

      // Start date of tenant is before or same the current visible start date
      if (startDate.isSameOrBefore(this.visibleStartDate, 'day')) {
        tileStartDateOrder = 1;
      } else {
        tileStartDateOrder = dayIndex + 1;
      }

      // End date of tenant is after or same the current visible last date
      if (endDate.isSameOrAfter(this.visibleEndDate, 'day')) {
        tileEndDateOrder = this.visibleStartDate.daysInMonth();
      } else {
        const orderEndDateIndex = this.days.findIndex(day =>
          this.isDateSame(day.date, endDate)
        );

        if (orderEndDateIndex !== -1) {
          tileEndDateOrder = orderEndDateIndex + 1;
        }
      }

      /* 
        Notes: only reduce block when block hasAutoextend and -
        isAutoextend block are in the same date range
      */
      if (
        (hasAutoextend && !hasEndInFuture) ||
        (isAutoextend && !hasStartInPast)
      ) {
        autoextendReduction = HALF_TILE_WIDTH;
      }

      const totalTile = tileEndDateOrder - (tileStartDateOrder - 1);
      const widthOfOccupiedLabel = TILE_WIDTH * totalTile - autoextendReduction;

      return totalTile > 0 ? widthOfOccupiedLabel : 0;
    },

    updateDataUnitTypes(unitTypes, days) {
      if (!unitTypes && !days) return [];

      return unitTypes.map(unitType => {
        const rooms = unitType.rooms.map(room => {
          const tenants = [...room.contract_data];
          const outOfOrders = [];

          const outOfOrderData = room.out_of_order_data || [];

          if (outOfOrderData.length) {
            outOfOrderData.forEach(data => {
              outOfOrders.push({
                ...data,
                booking_status: 'Out of Order',
                tenant_name: '',
                isOutOfOrder: true,
                outOfOrderCategory: data.category || '',
                outOfOrderNote: data.note || '',
              });
            });
          }

          const allotmentData = [...tenants, ...outOfOrders];

          return {
            ...room,
            days: days.map((day, dayIndex) => {
              const visibleLastDay = days[days.length - 1];
              const updatedDay = {
                ...day,
                tenants: [],
              };

              // eslint-disable-next-line no-restricted-syntax
              for (const [indexTenant, tenant] of [
                ...allotmentData,
              ].entries()) {
                const stayDate = this.getStayDate(tenant);

                if (stayDate) {
                  const { startDate, endDate } = stayDate;

                  const hasLabelAtTheVisibleStartDate =
                    dayIndex === 0 &&
                    startDate.isBefore(day.date, 'day') &&
                    endDate.isSameOrAfter(day.date, 'day');

                  const hasLabelAtTheVisibleDays = startDate.isSame(
                    day.date,
                    'day'
                  );

                  if (
                    hasLabelAtTheVisibleStartDate ||
                    hasLabelAtTheVisibleDays
                  ) {
                    const hasStartInPast = startDate.isBefore(
                      this.visibleStartDate,
                      'day'
                    );

                    const hasEndInFuture = endDate.isAfter(
                      visibleLastDay.date,
                      'day'
                    );

                    const hasAutoextend = tenant.is_have_unpaid_extend;

                    const isAutoextend = tenant.is_auto_extend_unpaid;

                    const widthLabel = this.getWidthOfOccupiedLabel({
                      startDate,
                      endDate,
                      dayIndex,
                      hasAutoextend,
                      isAutoextend,
                      hasStartInPast,
                      hasEndInFuture,
                    });

                    const onlyATile =
                      endDate.isSameOrBefore(startDate, 'day') ||
                      widthLabel <= TILE_WIDTH;

                    const updatedTenant = {
                      ...tenant,
                      widthLabel,
                      hasStartInPast,
                      hasEndInFuture,
                      onlyATile,
                      hasAutoextend,
                      isAutoextend,
                    };

                    updatedDay.tenants.push(updatedTenant);
                    tenants.splice(indexTenant, 1);
                  }
                }
              }

              return updatedDay;
            }),
          };
        });

        return { ...unitType, rooms };
      });
    },

    handleClickDayHead(selectedDate) {
      this.$emit('date-focused', selectedDate);
    },

    getActiveDay(days) {
      return days.find(day => day.isActive) || {};
    },

    handleClickTenantLabel(contract, room) {
      if (contract.is_auto_extend_unpaid) {
        return;
      }

      this.$emit('action-click-tenant-label', { ...contract, room });
    },

    getValidDate(date) {
      return !!date && dayjs(date).isValid() ? dayjs(date) : null;
    },

    getStayDate(tenant) {
      const startDate = this.getValidDate(tenant.start_date);
      if (!startDate) {
        return null;
      }

      let endDate = this.getValidDate(tenant.end_date);
      const bookingStatus = tenant.booking_status;
      const actualCheckoutDate = this.getValidDate(tenant.actual_checkout_date);
      const terminatedDate = this.getValidDate(tenant.terminated_date);
      const isTerminated = bookingStatus === 'terminated' && !!terminatedDate;

      // Terminated Case
      if (isTerminated) {
        endDate = terminatedDate;
      }

      // Has actual checkout date
      else if (actualCheckoutDate) {
        endDate = actualCheckoutDate;
      }

      // End before stay
      const isEndedBeforeStay = endDate && endDate.isBefore(startDate);
      if (isEndedBeforeStay) {
        return null;
      }

      return { startDate, endDate };
    },
  },
};
</script>

<style lang="scss" src="./CalendarViewDaily.scss"></style>
