<template>
  <div class="calendar-view" :class="rootClasses">
    <div class="cv-sidebar cv-sidebar__left">
      <template v-if="isShowCalenderView">
        <div v-for="(unitType, i) in calendarViewData" :key="`unit-type-${i}`">
          <div
            class="cv__tile invisible-border cv__room-type-label"
            :title="unitType.unit_type"
          >
            <bg-text size="body-1">
              <span>{{ unitType.unit_type }}</span>
            </bg-text>
          </div>

          <div
            v-for="(room, roomIndex) in unitType.rooms"
            :key="`room-type-${i}-room-number-${roomIndex}`"
            class="cv__tile invisible-border cv__room-number"
          >
            <div
              @click="handleClickRoom(room)"
              :title="`Lantai ${room.room_floor}`"
              class="cv__room-number-label"
              tabindex="0"
            >
              <bg-text size="body-1">
                <span class="cv-sidebar__room-number">
                  {{ room.room_name }}
                </span>
              </bg-text>
              <bg-text size="body-4" class="text-muted">
                <span>{{ `Lantai ${room.room_floor}` }}</span>
              </bg-text>
            </div>

            <template v-if="isShowRoomActionMenu(room)">
              <div
                role="menu"
                variant="shadow"
                class="cv__room-number-action-menu"
              >
                <div role="menuitem" @click="handleRoomStatusClicked(room)">
                  Buat status Out of Order
                </div>
              </div>

              <div
                class="cv__room-number-action-menu-overlay"
                @click="isShowRoomActionMenuDropdown = false"
              />
            </template>
          </div>
        </div>
      </template>
    </div>

    <calendar-view-daily
      data-testid="calendarViewDaily"
      :past-day-length="0"
      :future-month-limit="6"
      :pass-year-limit="3"
      :start-date="visibleStartDate"
      :scroll-left="scrollLeft"
      @content-scrolled="$emit('content-scrolled', $event)"
      @days-changed="$emit('days-changed', $event)"
      @action-reached-edge="$emit('action-reached-edge', $event)"
      @on-swipe-date="$emit('on-swipe-date', $event)"
      @date-focused="$emit('date-focused', $event)"
      @action-click-tenant-label="handleClickTenantLabel"
    />

    <div class="cv-sidebar cv-sidebar__right" />
  </div>
</template>

<script>
import { BgText } from 'bangul-vue';
import EventBus from 'Utils/event-bus';
import { mapState, mapMutations } from 'vuex';
import { AsyncState } from '@admin/store/modules/calendarView';
import { timeline } from './utils';
import CalendarViewDaily from './partials/CalendarViewDaily';

export default {
  name: 'CalendarView',

  components: {
    CalendarViewDaily,
    BgText,
  },

  props: {
    hasAccessOutOfOrder: {
      type: Boolean,
      default: false,
    },
    scrollLeft: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      timeline,
      visibleStartDate: '',
      selectedRoom: null,
      isShowRoomActionMenuDropdown: false,
    };
  },

  computed: {
    ...mapState('calendarView', ['asyncState', 'calendarViewData']),

    rootClasses() {
      return {
        'calendar-view--no-data': this.asyncState === AsyncState.NO_DATA,
        'calendar-view--loading': this.asyncState === AsyncState.LOADING,
      };
    },
    isShowCalenderView() {
      return this.asyncState === AsyncState.SUCCESS;
    },
    isShowRoomActionMenu() {
      return room =>
        this.hasAccessOutOfOrder &&
        this.isShowRoomActionMenuDropdown &&
        !!this.selectedRoom &&
        room?.room_id === this.selectedRoom?.room_id;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(value) {
        this.handleRouteChange(value);
      },
    },
    isShowRoomActionMenuDropdown(isShow) {
      if (!isShow) {
        this.selectedRoom = null;
      }
    },
  },

  methods: {
    ...mapMutations({
      xSetAsyncState: 'calendarView/setAsyncState',
    }),

    handleRouteChange({ query }) {
      if (query.start_date) {
        this.visibleStartDate = query.start_date;
      } else {
        this.visibleStartDate = '';
      }
    },

    swipeDate(selectedTimeline) {
      EventBus.$emit('cv-swipe-date', selectedTimeline);
    },

    handleClickRoom(room) {
      this.selectedRoom = room;

      if (this.hasAccessOutOfOrder) {
        this.isShowRoomActionMenuDropdown = true;
      } else {
        this.handleRoomStatusClicked(room);
      }
    },

    handleRoomStatusClicked(room) {
      this.selectedRoom = null;
      this.isShowRoomActionMenuDropdown = false;
      this.$emit('on-click-room', room);
    },

    handleClickTenantLabel(day) {
      this.$emit('action-click-tenant-label', day);
    },
  },
};
</script>

<style src="./CalendarView.scss" lang="scss"></style>
