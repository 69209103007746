export const STATUS_DATA = {
  booked: 'booked',
  confirmed: 'confirmed',
  verified: 'verified',
  dbet: 'DBET',
  checkedIn: ['check_in', 'checked_in', 'check-in'],
  terminated: 'terminated',
  finished: 'finished',
  relocated: 'relocated',
  cancelled: 'cancel_by_admin',
};

const {
  booked,
  confirmed,
  verified,
  dbet,
  checkedIn,
  terminated,
  finished,
  relocated,
  cancelled,
} = STATUS_DATA;

const STATUS_DATA_MAPPING = [
  {
    status: [booked, confirmed],
    color: 'blue',
    text: 'Waiting for payment',
  },
  {
    status: [verified],
    color: 'blue',
    text: 'Waiting for check-in',
  },
  {
    status: [dbet],
    color: 'green',
    text: 'Stay - DBET',
  },
  {
    status: [...checkedIn],
    color: 'green',
    text: 'Stay - Booking',
  },
  {
    status: [terminated],
    color: 'red',
    text: 'Terminated',
  },
  {
    status: [finished],
    color: 'red',
    text: 'Finished',
  },
  {
    status: [cancelled],
    color: 'red',
    text: 'Cancelled',
  },
  {
    status: [relocated],
    color: 'grey',
    text: 'Relocated',
  },
];

export const getBookingStatus = value => {
  if (!value) return null;

  const bookingStatus = value.toLowerCase();

  const { color = 'white', text = '' } =
    STATUS_DATA_MAPPING.find(statusData => {
      const isContainBookingStatus = statusData.status.some(
        status => status.toLowerCase() === bookingStatus
      );

      return isContainBookingStatus;
    }) || {};

  return { color, text };
};
