<template>
	<div class="sentinel">
		<div v-if="loading" class="sentinel__loader"></div>
	</div>
</template>

<script>
export default {
	name: 'InfiniteScroll',

	props: {
		loading: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			observer: null,
		};
	},

	beforeDestroy() {
		this.unobserve();
	},

	methods: {
		setup() {
			this.observer = new IntersectionObserver(this.handleIntersection);
			this.observer.observe(this.$el);
		},

		handleIntersection([entry]) {
			if (entry.isIntersecting && !this.loading) {
				this.$emit('on-fire');
			}
		},

		unobserve() {
			this.observer && this.observer.unobserve(this.$el);
		},
	},
};
</script>

<style lang="scss" scoped>
.sentinel {
	margin-left: 610px;
	&__loader {
		margin-top: 20px;
		display: block;
		width: 35px;
		height: 35px;
		border: 3px solid transparent;
		border-top-color: #8f226e;
		border-radius: 50%;
		animation: spin 0.8s linear infinite;
	}

	@media (max-width: 1380px) {
		margin: 0;
		display: flex;
		justify-content: center;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
